import React from "react";
import { ITask } from "../Interfaces";


interface Props {
  task: ITask;
  completeTask(task_id_to_delete: string);
}

const TodoTask = ({ task, completeTask }: Props) => {
  return (
    <div className="task">
      <div className="content">
        <span>{task.task}</span>
        <span>{task.deadline}</span>
      </div>
      <button
        onClick={() => {
          console.log("removing task --------> ", task.task)
          completeTask(task.id);         
        }}
      >
        delete
      </button>
    </div>
  );
};

export default TodoTask;
