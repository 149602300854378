import React, { FC, ChangeEvent, useState, useEffect } from "react";
import "./App.css";
import TodoTask from "./Components/TodoTask";
import { ITask } from "./Interfaces";
import { v4 as uuidv4 } from 'uuid';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from 'react-hot-toast';
const delete_toast = () => toast('task  deleted', {
  style: {
    border: '1px solid black',
    background: '#FF3131',
    color: '#000000',
  }
});
const task_added_toast = () => toast('task added', {
  style: {
    border: '1px solid black',
    background: '#32CD32',
    color: '#000000',
  }
});

const App: FC = () => {
  const [task, setTask] = useState<string>("");
  const [deadline, setDealine] = useState<number>(0);
  const [todoList, setTodoList] = useState<ITask[]>([]);
  const [shouldFetch, setShouldFetch] = useState<boolean>(true)
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL || "https://todo-ts-backend-worker.niharikareddy.workers.dev";
  const post_url: string = `${REACT_APP_BASE_URL}/create_task`;
  const delete_url: string = `${REACT_APP_BASE_URL}/delete_task`;


 

  const fetch_data = async () => {
    const res = await fetch(`${REACT_APP_BASE_URL}/task_list`)

    const res_json = await res.json();

    console.log("fetched data-------->  ", res_json);
    if (Array.isArray(res_json.data)) {
      const task_list = res_json.data;
      setTodoList(task_list);
    } else {
      console.error("Data is not an array.");
    }
    
    setShouldFetch(false)
  }


  useEffect(() => {
    if (shouldFetch) {
      (async () => {
        await fetch_data()
      })();
    }
     // eslint-disable-next-line
  }, [shouldFetch])

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.name === "task") {
      setTask(event.target.value);
    } else {
      setDealine(Number(event.target.value));
    }
  };


  const addTask = async () => {
    var id = uuidv4();
    const newTask = { id, task, deadline };
    const res = await fetch(post_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newTask)
    }).catch((e) => {
      console.warn('error while posting', e)
      return null
    });
    if (!res) return;

    const response_body = await res.json().catch((e) => {
      console.warn('error while json parsing data : ', e);
      return null;
    })
    if (!response_body) return null;
    task_added_toast()
    setTodoList([...todoList, newTask]);
    setShouldFetch(true)
    setTask("")
    setDealine(0);
  }
  const completeTask = async (id: string) => {
    const res = await fetch(delete_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(id)
    }).catch((err) => {
      console.warn('error while deleting data')
    })
    if (res) {
      delete_toast()
      setShouldFetch(true)
    } else { return }
    const response_body = await res.json().catch((err) => {
      console.warn('error while json parsing data : ', err);
    })
    if (!response_body) {
      console.warn('no response body')
    }

  }


  return (
    <div className="App">
      <div className="header">
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Task..."
            name="task"
            value={task}
            onChange={handleChange}
          />
           <input
            type="number"
            placeholder="Deadline (in Days)..."
            name="deadline"
            value={deadline}
            onChange={handleChange}
          />
         
        </div>
       
        <button onClick={addTask}>Add Task</button>


      </div>
      <div className="todoList">
        {todoList.map((task: ITask, key: number) => {
          return <TodoTask key={key} task={task} completeTask={completeTask} />;
        })}
      </div>
    </div>
  );

};

export default App;
